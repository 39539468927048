<template>
  <div style="text-align: left">广告信息</div>
  <el-button
    type="primary"
    style="display: flex; margin-top: 15px"
    size="mini"
    @click="pushtoad"
    >新增广告</el-button
  >
  <el-table :data="tableData" style="margin-top: 30px">
    <el-table-column type="index"></el-table-column>
    <el-table-column label="广告媒体文件">
      <template #default="scope">
        <img
          v-if="!scope.row.isvideo && scope.row.coverUrl"
          :src="imgBaseUrl + scope.row.coverUrl"
          alt=""
          style="width: 80px; height: 120px; object-fit: contain"
          @click="newwindow(imgBaseUrl + scope.row.coverUrl)"
        />
        <video
          v-else-if="scope.row.isvideo &&  scope.row.coverUrl"
          :src="videoBaseUrl + scope.row.coverUrl"
          alt=""
           @click="newwindow(videoBaseUrl + scope.row.coverUrl)"
          style="width: 80px; height: 120px; object-fit: contain"
        />
      </template>
    </el-table-column>
    <el-table-column label="广告标题" prop="caption"></el-table-column>
    <el-table-column label="广告内容" prop="content"></el-table-column>
    <el-table-column label="广告位置" prop="location">
        <template #default="scope">
        <el-tag v-if="scope.row.location === 'INDEX'" type="success">首页</el-tag>
        <el-tag v-else-if="scope.row.location === 'CLASS'" type="primary"
          >云课堂</el-tag
        >
         <el-tag v-else-if="scope.row.location === 'GROUP'" type="info"
          >团购</el-tag
        >
         <el-tag v-else-if="scope.row.location === 'LIVE'" type="warning"
          >直播</el-tag
        >
      </template>
    </el-table-column>
    <el-table-column label="开始时间" prop="startDate"></el-table-column>
    <el-table-column label="结束时间" prop="endDate"></el-table-column>

    <el-table-column label="展示状态" prop="showStatus">
      <template #default="scope">
        <el-tag v-if="scope.row.adopted === true" type="success">展示</el-tag>
        <el-tag v-else-if="scope.row.adopted === false" type="info"
          >隐藏</el-tag
        >
      </template>
    </el-table-column>

    <el-table-column label="备注" prop="note"></el-table-column>
    <el-table-column label="操作">
      <template #default="scope">
        <el-button
          style="margin-right: 10px"
          type="text"
          @click="changeshowstate(scope.row)"
          >{{ scope.row.adopted ? "隐藏" : "展示" }}</el-button
        >
        <el-button
          style="margin-right: 10px"
          type="text"
          @click="editAd(scope.row)"
          >编辑</el-button
        >
        <el-button
          style="margin-left: 0px !important"
          type="text"
          @click="deleteAd(scope.row)"
          >删除</el-button
        >
        <el-button
          style="margin-left: 0px !important"
          type="text"
          @click="submitad(scope.row)"
          >设为轮播</el-button
        >
      </template>
    </el-table-column>
  </el-table>
  <el-pagination
    @size-change="handleSizeChange"
    @current-change="handleCurrentChange"
    :current-page="currentPage"
    :page-sizes="[10, 20, 30]"
    :page-size="pageSize"
    layout="total, sizes, prev, pager, next, jumper"
    style="margin-top: 20px"
    :total="totalElement"
  >
  </el-pagination>
  <!-- <el-button type="primary" style="display: flex; margin-top: 15px" size="mini"
    >保存</el-button
  > -->
</template>
<script>
export default {
  data() {
    return {
      tableData: [],
      currentPage: 1,
      totalElement: 0,
      pageSize: 0,
      imgBaseUrl: '',
      videoBaseUrl: ''
    }
  },
  created() {
    const Arr = [
      {
        name: '首页',
        index: '/index'
      },
      {
        name: '首页管理',
        index: '/swiper'
      },
      {
        name: '广告管理',
        index: ''
      }]
    this.$store.commit('setBreadInfo', Arr)
    // this.imgBaseUrl = this.$http.defaults.baseURL + 'nktdec/uploader/images/'
    this.imgBaseUrl = 'http://nktdec.ctvideo.cn/images/'
    this.videoBaseUrl = 'http://nktdec.ctvideo.cn/videos/'

    this.getAdInfo(1)
  },
  mounted() {
    this.$nextTick(() => {
    })
  },
  watch: {
  },
  methods: {
    async handleSizeChange(val) {
      console.log(val)
      const res = await this.$http.get('/nktdec/adinfo/adinfos/1' + '?pageSize=' + val)
      this.tableData = res.data.content
      console.log(res)
      this.totalElement = res.data.totalElements
      this.pageSize = res.data.size
    },
    async handleCurrentChange(val) {
      console.log(val)
      var str = val + '?pageSize=' + this.pageSize
      const res = await this.$http.get('/nktdec/adinfo/adinfos/' + str)
      this.tableData = res.data.content
      console.log(res)
      this.totalElement = res.data.totalElements
      this.pageSize = res.data.size
    },
    async getAdInfo(pageno) {
      const res = await this.$http.get('/nktdec/adinfo/adinfos/' + pageno)
      // console.log(res)
      res.data.content.forEach(item => {
        console.log(item.coverUrl.split('.')[item.coverUrl.split('.').length - 1])
        if (item.coverUrl.split('.')[item.coverUrl.split('.').length - 1] === 'mp4') {
          item.isvideo = true
        } else {
          item.isvideo = false
        }
      })
      this.tableData = res.data.content
      this.totalElement = res.data.totalElements
      this.pageSize = res.data.size
      console.log(this.tableData)
    },
    async submitad(row) {
      const res = await this.$http.put('/nktdec/adinfo/banner/' + row.id)
      console.log(res)
      if (res.data.code === '1') this.$message.success('设置成功')
    },
    async changeshowstate(row) {
      console.log(row)
      row.adopted = !row.adopted
      const res = await this.$http.post('/nktdec/adinfo/adinfos', row)
      console.log(res)
      if (res.data.code === '1') this.$message.success('设置成功！')
      this.getAdInfo(1)
    },
    onSubmit() {
      console.log('我被提交了')
    },
    newwindow(path) {
      window.open(path, '__blank')
    },
    editAd(row) {
      // console.log(row)
      window.sessionStorage.setItem('ADinfo', JSON.stringify(row))
      this.$store.commit('setAdInfo', row)
      this.$router.push('/changead/false')
    },
    pushtoad() {
      this.$router.push('/changead/true')
    },
    async deleteAd(row) {
      console.log(row)
      this.$confirm('此操作将永久删除该轮播, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const res = await this.$http.delete('/nktdec/adinfo/adinfo/' + row.id)
        // if (res.)
        console.log(res)
        if (res.data.code === '1') {
          this.getAdInfo(1)
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
        } else {
          this.$message({
            type: 'error',
            message: res.data.message
          })
        }
      })
    },
  }
}
</script>
<style scoped>
</style>
