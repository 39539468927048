<template>
  <div style="text-align: left">轮播信息</div>
  <el-form
    :model="swiperform"
    :rules="rules"
    ref="ruleForm"
    label-width="100px"
    style="margin-top: 30px"
    class="demo-ruleForm"
  >
    <el-form-item
      label="轮播标题"
      size="small"
      prop="caption"
      style="width: 50%"
    >
      <div style="display: flex">
        <el-input
          v-model="swiperform.caption"
          clearable
          placeholder="请输入轮播标题"
          style="width: 230px"
        ></el-input>
      </div>
    </el-form-item>
    <el-form-item
      label="轮播时间"
      size="small"
      prop="teamTimeproid"
      style="width: 50%; text-align: left"
    >
      <el-date-picker
        v-model="swiperform.showDate"
        type="datetime"
        range-separator="至"
        placeholder="请选择开始时间"
      >
      </el-date-picker>
    </el-form-item>
    <el-form-item
      label="轮播图片"
      prop="goodsPic"
      class="goodpic"
      style="width: 50%"
    >
      <el-upload
        action="#"
        list-type="picture-card"
        style="display: flex; justify-content: flex-start"
        :auto-upload="false"
        accept=""
        :file-list="teamlist"
        :on-change="filechange"
        :limit="1"
        :on-exceed="handleexceed"
      >
        <template #default>
          <i class="el-icon-plus"></i>
        </template>
        <template #file="{ file }">
          <div style="width: 100%; height: 100%">
            <img
              class="el-upload-list__item-thumbnail"
              :src="file.url"
              alt=""
            />
            <span class="el-upload-list__item-actions">
              <span
                class="el-upload-list__item-delete"
                @click="handleRemove(file)"
              >
                <i class="el-icon-delete"></i>
              </span>
            </span>
          </div>
        </template>
      </el-upload>
      <span style="color: rgba(0, 0, 0, 0.45); text-align: left"
        >建议尺寸：16:9宽高比，只可设置一张图片</span
      >
    </el-form-item>
    <el-form-item label="链接地址" size="small" style="width: 50%">
      <div style="display: flex">
        <el-input
          v-model="swiperform.linkUrl"
          clearable
          placeholder="请输入轮播位置"
          style="width: 230px"
        ></el-input>
      </div>
    </el-form-item>
    <el-form-item label="轮播位置" size="small" style="width: 50%">
      <div style="display: flex">
        <el-input
          v-model="swiperform.place"
          clearable
          placeholder="请输入轮播位置"
          style="width: 230px"
        ></el-input>
      </div>
    </el-form-item>
    <el-form-item
      label="轮播类型"
      prop="adopted"
      class="swipertype"
      style="width: 50%"
    >
      <el-select v-model="swiperform.type" placeholder="请选择">
        <el-option
          v-for="(item, index) in typeoption"
          :key="index"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="是否展示" prop="adopted" style="width: 50%">
      <div class="displayflex" style="margin-top: 10px">
        <el-switch
          v-model="swiperform.adopted"
          active-color="#13ce66"
          inactive-color="#ff4949"
        >
        </el-switch>
      </div>
    </el-form-item>
  </el-form>
  <div class="displayflex">
    <el-button
      type="primary"
      size="mini"
      style="margin-left: 30px"
      @click="onsubmit"
      >提交</el-button
    >
  </div>
</template>
<script>
export default {
  data() {
    return {
      swiperform: {
        caption: '',
        type: '',
        pictureUrl: '',
        linkUrl: '',
        showDate: '',
        adopted: true,
        place: 1
      },
      rules: {
        caption: [
          { required: true, message: '请输入轮播主题！', trigger: 'blur' }
          //   { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        showDate: [
          { required: true, message: '请选取轮播活动时间！', trigger: 'blur' }
          //   { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
      },
      typeoption: [{
        label: '自定义',
        value: 'BANNER'
      }, {
        label: '课程',
        value: 'CLASS'
      }],
      imgBaseUrl: '',
      baseUrl: '',
      teamlist: [],
      //   shortcuts: [{
      //     text: '最近一周',
      //     value: (() => {
      //       const end = new Date()
      //       const start = new Date()
      //       start.setTime(start.getTime() + 24 * 60 * 60 * 1000)
      //       end.setTime(start.getTime() + 7 * 24 * 60 * 60 * 1000)
      //       // start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
      //       return [start, end]
      //     })()
      //   }]
    }
  },
  methods: {
    handleexceed() {
      this.$message.error('仅限上传1个媒体文件！')
    },
    handleRemove(file) {
      this.teamlist = []
      this.swiperform.pictureUrl = ''
    },
    async onsubmit() {
      console.log(this.swiperform)
      if (this.swiperform.pictureUrl.length === 0) {
        this.$message.error('请上传图片！')
        return
      }
      const data = {}
      if (this.$route.params.isadd === 'true') {
        data.caption = this.swiperform.caption
        data.type = this.swiperform.type
        data.pictureUrl = this.swiperform.pictureUrl
        data.showDate = this.formatdate(this.swiperform.showDate)
        data.linkUrl = this.swiperform.linkUrl
        data.adopted = this.swiperform.adopted
        data.place = this.swiperform.place
      } else {
        data.id = this.swiperform.id
        data.caption = this.swiperform.caption
        data.type = this.swiperform.type
        data.pictureUrl = this.swiperform.pictureUrl
        data.showDate = '2021-05-21'
        data.linkUrl = this.swiperform.linkUrl
        data.adopted = this.swiperform.adopted
        data.place = this.swiperform.place
      }

      console.log(data)
      const res = await this.$http.post('/nktdec/bannerinfo/bannerinfos', data)
      console.log(res)
      if (res.data.code === '1') {
        this.$message.success('轮播设置成功！')
        this.$router.push('/swiperinfo')
      }
    },
    formatdate(date) {
      var datestr = date.toLocaleDateString().split('/').join('-')
      var str = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
      str += ':'
      str += date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
      str += ':'
      str += date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
      return datestr + ' ' + str
    },
    async filechange(file, fileList) {
      const formData = new FormData()
      formData.append('file', file.raw)
      formData.append('params', '参数')
      const res = await this.$http(
        {
          method: 'post',
          url: '/nktdec/uploader/uploadfile',
          data: formData,
          headers: {
            'Content-Type': 'multipart/form-data;',
          }
        }
      )
      this.teamlist.push({
        url: this.imgBaseUrl + res.data.result
      })
      // const coverurl = await this.$http.get('/nktdec/uploader/images/' + res.data.result)
      // console.log(this.baseUrl + coverurl.config.url.substring(1))
      this.swiperform.pictureUrl = res.data.result
    },

  },
  computed: {
    SwiperInfo() {
      return this.$store.state.SwiperInfo
    },
  },
  created() {
    const Arr = [
      {
        name: '首页',
        index: '/index'
      },
      {
        name: '首页管理',
        index: '/swiperinfo'
      },
      {
        name: '轮播管理',
        index: '/swiperinfo'
      }, {
        name: '编辑轮播',
        index: ''
      }]
    this.$store.commit('setBreadInfo', Arr)
    this.baseUrl = this.$http.defaults.baseURL
    this.imgBaseUrl = 'http://nktdec.ctvideo.cn/images/'
    if (this.$route.params.isadd === 'false') {
      this.swiperform = this.SwiperInfo
      console.log(this.swiperform)
      this.teamlist.push({
        url: this.imgBaseUrl + this.swiperform.pictureUrl
      })
      console.log(this.teamlist)
    }
  },
}
</script>
<style scoped>
</style>
